<template>
  <div>
    <Header :type="'-2'" />
    <div class="us-page">
      <img src="@/assets/mine/concatBg.png" />
      <div class="from-page">
        <div>
          <div class="title"><span class="red">*</span>为必填项</div>
          <el-form
            :model="addForm"
            :rules="addFormRules"
            ref="addForm"
            class="form-box"
            label-width="100px"
          >
            <el-form-item prop="realName" label="昵称：">
              <el-input
                placeholder="请输入姓名"
                v-model="addForm.realName"
                class="from-input"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="phone" label="手机号：">
              <el-input
                placeholder="请输入手机号邮箱"
                v-model="addForm.phone"
                class="from-input from-input-login"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="emial" label="邮箱：">
              <el-input
                placeholder="请输入邮箱"
                v-model="addForm.emial"
                class="from-input"
              >
              </el-input>
            </el-form-item>
            <el-form-item prop="content" label="性别：">
              <el-input
                type="textarea"
                :rows="3"
                placeholder="请输入查询内容"
                v-model="addForm.content"
                class="from-input"
              >
              </el-input>
            </el-form-item>
            <div @click="save('addForm')">
              <el-button :loading="isClicklType" class="login-from-submit"
                >完成</el-button
              >
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header-one/index.vue";
export default {
  components: { Header },
  data() {
    var checkEmail = (rule, value, callback) => {
      const reg = /^\w{3,}(\.\w+)*@[A-z0-9]+(\.[A-z]{2,5}){1,2}$/;
      if (reg.test(value)) {
        callback();
      } else {
        return callback(new Error("请输入正确的邮箱"));
      }
    };
    return {
      addForm: {
        realName: "",
        phone: "",
        emial: "",
        content: "",
      },
      addFormRules: {
        realName: [{ required: true, message: "请输入姓名", trigger: "blur" }],
        phone: [{ required: true, message: "请输入电话", trigger: "blur" }],
        content: [{ required: true, message: "请输入内容", trigger: "blur" }],
        emial: [{ validator: checkEmail, trigger: "blur" }],
      },
      isClicklType: false,
    };
  },
  methods: {
    save(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
        } else {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.us-page {
  width: 100%;
  //   height: calc(100vh - 260px);
  background: #f5f8fa;
  position: relative;
  padding-bottom: 123px;
  img {
    width: 100%;
    height: 360px;
  }
  .from-page {
    position: relative;
    width: 1200px;
    height: 460px;
    background: #ffffff;
    font-size: 14px;
    font-family: Source Han Sans CN-Regular, Source Han Sans CN;
    font-weight: 400;
    color: #444444;
    margin: -60px auto;
    z-index: 99;

    > div {
      width: 610px;
      margin: 0px auto;
      padding: 30px 10px;
      text-align: left;
    }
    .title {
      margin-left: 20px;
      margin-bottom: 20px;
    }
    .red {
      color: rgba(245, 57, 76, 1);
    }
  }
  .form-box {
    width: 600px;
    margin: 0 auto;
    // margin-left: 20px;
    text-align: left;
    .login-from-submit {
      width: 120px;
      height: 40px;
      background: #f5394c;
      border-radius: 4px 4px 4px 4px;
      color: #fff;
      font-size: 16px;
      font-family: Source Han Sans CN-Regular, Source Han Sans CN;
      font-weight: 400;
      border: none;
      margin-left: 70px;
      margin-top: 15px;
    }
    /deep/.el-input__inner {
      position: relative;
      display: flex;
      align-items: center;
      height: 40px;
      line-height: 40px;
      background: #fff !important;
      border-radius: 4px;
      opacity: 1;
      border: 1px solid #cccccc;
      border-radius: 2px;
      display: flex;
      justify-content: space-between;
      // margin-bottom: 20px;
      color: #444444 !important;
    }
  }
}
</style>
